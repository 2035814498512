<template>
  <div class="page-layout" v-if="pageData && pageData.attributes">
    <ProjectsMapbox />
  </div>
</template>

<script>
import ProjectsMapbox from '../components/ProjectsMapbox.vue'
export default {
  name: 'about',
  components: {
    ProjectsMapbox
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "QTEdu European Pilot Projects",
          tag: "",
          subtitle: ""
        }
      },
      currentPageID: ""
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  methods: {},
  mounted() {},
  watch: {}
}
</script>

<style lang="scss" scoped>
</style>