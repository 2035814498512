<template>
    <div class="pannello map-layout">

        <div class="moving-tooltip" :style="moving">
            <h2>{{ hoverMembers }}</h2>
        </div>

        <div class="map" ref="map"></div>

        <div class="button-grid" :class="[this.showButtons ? 'fixed' : 'hidden']">
            <div @click="toggleButtons()" :class="['icon-right', this.showButtons ? 'read-more upside' : 'read-more']">
                <p>Filter by Level</p>
            </div>

            <div v-if="this.selectedTarget!=''" class="selected-wg icon-right close" @click="clearWG()">
                {{ this.selectedTarget }}
            </div>

            <button @click="selectTarget('Schools')"
                :class="{'btn border': true, 'active': selectedTarget == 'Schools'}">
                <div class="pj-icon schools"></div>
                <span>Primary and Secondary School</span>
            </button>
            
            <button @click="selectTarget('Higher education')"
                :class="{'btn border': true, 'active': selectedTarget == 'Higher education'}">
                <div class="pj-icon higher-education"></div>
                <span>Higher Education</span>
            </button>
            
            <button @click="selectTarget('Industry')"
                :class="{'btn border': true, 'active': selectedTarget == 'Industry'}">
                <div class="pj-icon industry"></div>
                <span>Industry</span>
            </button>
            
            <button @click="selectTarget('General public')"
                :class="{'btn border': true, 'active': selectedTarget == 'General public'}">
                <div class="pj-icon general-public"></div>
                <span>Outreach</span>
            </button>
        </div>

        <div class="map-info">

            <div class="wg-map-info">
                <div class="box first">
                    <div class="tot-members" v-if="this.projectsData">

                        <p class="num" v-if="this.selectedTarget!='' && this.selectedPjs==0">
                            {{ this.filteredProjects.length }}
                        </p>
                        <!-- se seleziono country -->
                        <p class="num" v-if="this.selectedPjs!=0">{{ this.selectedPjs }}</p>
                        <!-- se non seleziono niente -->
                        <p class="num" v-if="this.selectedTarget=='' && this.selectedPjs==0 || this.selectedPjs==null">
                            {{ this.projectsData.length }}
                        </p>

                        <p v-if="this.selectedCode=='country-total' || this.selectedCode=='country-pj'"
                            class="didascalia">
                            Pilot Project/s in
                            <span class="country">{{ this.selectedName }}</span>
                        </p>

                        <p v-else class="didascalia">
                            <span class="country country-label">{{ this.selectedName }}
                            </span>
                            <span class="subject">Pilot Projects</span>
                        </p>

                    </div>

                    <div class="countries" v-if="selectedCode!='country-pj' && selectedCode!='country-total'">
                        <p class="num in"> {{ countryKeys.length }}</p>
                        <p class="didascalia">
                            <span class="country country-label">Total</span>
                            <span class="subject">Countries</span>
                        </p>
                    </div>

                    <div class="countries" v-if="selectedCode=='country-pj' || selectedCode=='country-total'">
                        <p class="num in"> {{ totalMembers }}</p>
                        <p class="didascalia">
                            <span>Total</span>
                            <span class="country">Members</span>
                        </p>
                    </div>
                </div>
            </div>

        </div>

        <div class="map-country-baloon">
            <div class="title">
                <h1 class="pink">QT European</h1>
                <h1 class="underlined">Pilot Projects</h1>
            </div>



  
          
        </div>
        <div class="legend-grid">
                <div class="legend-line">
                    <p>Number of projects in countries:</p>
                    <div class="bar"></div>
                </div>
            </div>

        <!-- PROJECTS LIST -->

        <div class="container" style="position: relative; margin-top: 3rem;" v-if="this.projectsData && this.projectsData.length">

            <h2>Pilot Projects
                {{ this.selectedTarget!='' ? 'for ' : '' }}
                <span class="pink">{{ this.selectedTarget!='' ?  this.selectedTarget : '' }} </span>
                {{ this.selectedCountry!='' ? 'in ' : '' }}
                <span class="pink">{{  this.selectedCountry!='' ? this.selectedCountry : '' }}</span>
            </h2>

            <div class="baloon">
                <span class="num">{{ this.filteredProjects.length }}</span>
                <span class="bold blue members">Pilot<br>Project/s</span>
            </div>

            <p class="members-teaser teaser pink" v-if="this.selectedCountry!='' || this.selectedTarget!=''">
                Active filters are hiding some of the Pilot Projects. To see all of them, please reset filters above to
                default.
            </p>

            <p class="members-teaser teaser">
                Here below the list of pilot projects involved in the selected topic.
            </p>

            <div class="container" v-if="this.loading">
                <br>
                <br>
                <h3>Loading data ...</h3>
                <br>
                <br>
            </div>

            <div class="container" v-if="!this.loading">
                <br><br>
                <div class="list-wrapper">

                    <ArticlesList nodetype="generic--list">

                        <div class="no-data" v-if="projectsData.length==0">
                            <h3>No Data</h3>
                        </div>
                        <!-- <div v-else>
                        <h4 class="nitems"><span>{{ projectsData.length }}</span> items</h4>
                    </div> -->

                        <div v-if="filteredProjects.length">
                            <ProjectCard v-for="(p, index) in filteredProjects" :key="'project'+index"
                                addclass="project" :articleData="p" :routesData="routesData" />
                        </div>
                        <div v-else>
                            <ProjectCard v-for="(p, index) in projectsData" :key="'project'+index" addclass="project"
                                :articleData="p" :routesData="routesData" />
                        </div>

                    </ArticlesList>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ArticlesList from '../components/layout-blocks/ArticlesList.vue'
    import ProjectCard from '../components/layout-blocks/ProjectCard.vue'
    import mapboxgl from 'mapbox-gl'
    mapboxgl.accessToken =
        'pk.eyJ1IjoiaW50ZXJmYXNlIiwiYSI6ImNqYW8zZGJkeTRjNXQzNHIxYmJpMWF6MnkifQ.TfWAUiqo6rDYVGid30Q6sA'
    import 'mapbox-gl/dist/mapbox-gl.css'
    import {
        fetchNodes
    } from '../libs/drupalClient'
    export default {
        name: 'projectsmapbox',
        components: {
            ProjectCard,
            ArticlesList
        },
        data() {
            return {
                map: null, //object to reference map after build
                projectsData: null, //full data of fetched projects
                selectedTarget: '', //parametro filtro progetti
                loading: true,

                selectedCode: '',
                selectedISO3: '',
                selectedName: 'Total',
                selectedPjs: 0,
                selectedCountry: '',
                totalMembers: 0,
                showButtons: false,

                countryKeys: [], //array parametri per colorare mappa
                darkMapColor: '#071150', //colore mappa 1
                baseMapColor: '#0f1b66', //colore mappa 2
                lightMapColor: 'rgb(220, 220, 220)', //colore mappa 3
                hoverMapColor: 'rgb(250, 250, 250)', //colore mappa 4

                tooltip_top: 0, //posizione tooltip y
                tooltip_left: 0, //posizione tooltip x
                hoverMembers: 0, //numero contenuto tooltip
                displayHover: false //visibilità tooltip
            }
        },
        computed: {
            moving() {
                return "pointer-events: none; z-index: 11; color: #fff; position: absolute; " +
                    "top: " + this.tooltip_top + "px; " +
                    "left: " + this.tooltip_left + "px; " +
                    "opacity: " + (this.displayHover ? "1" : "0") + ";"
            },
            route() {
                return this.$route
            },
            filteredProjects() {
                var fpjs = this.projectsData

                if (this.selectedTarget) {
                    fpjs = fpjs.filter(pj => pj.attributes.field_project_target.includes(this.selectedTarget))
                }

                if (this.selectedISO3) {
                    var mainmms = []

                    fpjs = fpjs.filter(pj => {
                        var found = false
                        //var countmms = 0

                        //comments to count total members of projects and not by organization / nationality
                        pj.field_ref_member.forEach(pm => {
                            if (pm.field_organization.length && pm.field_organization[0].attributes
                                .field_country) {
                                var c = this.$store.getters.getISO3(pm.field_organization[0].attributes
                                    .field_country.country)
                                if (c == this.selectedISO3 /* && !found */) {
                                    found = true
                                    //countmms += pj.field_ref_member.length
                                    if(!mainmms.includes(pm.attributes.title))
                                        mainmms.push(pm.attributes.title)
                                        //countmms += 1

                                }
                            }
                        })

                        //consider also project coordinators
                        pj.field_pilot_project_coordinator.forEach(pm => {
                            if (pm.field_organization.length && pm.field_organization[0].attributes
                                .field_country) {
                                var c = this.$store.getters.getISO3(pm.field_organization[0].attributes
                                    .field_country.country)
                                if (c == this.selectedISO3 /* && !found */) {
                                    found = true
                                    //countmms += pj.field_pilot_project_coordinator.length
                                    if(!mainmms.includes(pm.attributes.title))
                                        mainmms.push(pm.attributes.title)
                                        //countmms += 1

                                }
                            }
                        })

                        if (found) {
                            this.totalMembers = mainmms.length
                            //this.totalMembers = countmms
                        }

                        return found
                    })
                }

                return fpjs
            },
            routesData() {
                return this.$store.state.routesData
            }
        },
        methods: {
            toggleButtons() {
                this.showButtons = !this.showButtons
            },
            async clearWG() {
                this.selectedTarget = ''
                this.selectedCode = 'total'
                this.selectedName = 'Total'

                await this.fetchprojectsData()

                this.selectedCountry = ''
                this.selectedISO3 = ''
                if (this.map) {
                    this.map.removeLayer('countries-data')
                    this.colorCountries()
                }
            },
            colorCountries() {
                // Build a GL match expression that defines the color for every vector tile feature
                // Use the ISO 3166-1 alpha 3 code as the lookup key for the country shape
                const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];

                // Calculate color values for each country based on 'hdi' value
                var max = 0
                var keys = this.countryKeys

                for (const row of keys)
                    if (row[1] > max) max = row[1]

                for (const row of keys) {
                    // Convert the range of data values to a suitable color
                    const alpha = (row[1] / max) + 0.2;
                    var color = `rgba(220, 220, 220, ${alpha})`

                    matchExpression.push(row[0], color);
                }

                // Last value is the default, used where there is no data
                //matchExpression.push('rgb(255, 255, 255)');
                matchExpression.push(this.baseMapColor)

                // Add layer from the vector tile source to create the choropleth
                // Insert it below the 'admin-1-boundary-bg' layer in the style 
                this.map.addLayer({
                    'id': 'countries-data',
                    'type': 'fill',
                    'source': 'countries',
                    'source-layer': 'country_boundaries',
                    'paint': {
                        'fill-color': matchExpression
                    }
                }, 'state-stroke');


            },
            calcProjectsKeys(array) {
                var totalcodes = []

                // for each project
                array.forEach(pj => {

                    //create empty array of country involved
                    //console.log('------ PROJECT ' + pj.attributes.drupal_internal__nid + ' ------')
                    var pjcountries = []

                    //for each member of project, if country not already registered, register it in the array
                    pj.field_ref_member.forEach(m => {
                        if (m.field_organization.length && m.field_organization[0].attributes
                            .field_country) {
                            var c = m.field_organization[0].attributes.field_country.country
                            var isocode = this.$store.getters.getISO3(c)
                            if (!pjcountries.includes(isocode))
                                pjcountries.push(isocode)
                        }
                    })

                    //consider also project coordinators
                    pj.field_pilot_project_coordinator.forEach(m => {
                        if (m.field_organization.length && m.field_organization[0].attributes
                            .field_country) {
                            var c = m.field_organization[0].attributes.field_country.country
                            var isocode = this.$store.getters.getISO3(c)
                            if (!pjcountries.includes(isocode))
                                pjcountries.push(isocode)
                        }
                    })

                    //for each country involved in the project
                    pjcountries.forEach(pc => {

                        //the first country entry must be created from scratch
                        if (totalcodes.length == 0)
                            totalcodes.push([pc, 1])

                        //then find the correct entry and increase the total
                        else {
                            var found = false

                            totalcodes.forEach(tc => {
                                if (tc[0] == pc) {
                                    tc[1] += 1
                                    found = true
                                }
                            })

                            //if not already in the keys array of total, create a new entry
                            if (!found)
                                totalcodes.push([pc, 1])
                        }
                    })
                })
                this.countryKeys = totalcodes
            },
            async fetchprojectsData() {
                this.loading = true

                await fetchNodes('pilot_project', {
                        include: ['field_ref_member', 
                            'field_pilot_project_coordinator', 
                            'field_logo', 
                            'field_ref_working_group'
                        ],
                        nested_include: ['field_ref_member.field_organization', 'field_pilot_project_coordinator.field_organization']
                    })
                    .then(res => {
                        this.projectsData = res
                        this.loading = false
                        //console.log('projects', this.projectsData)
                        this.calcProjectsKeys(this.projectsData)
                    })
            },
            selectTarget(name) {
                //console.log('filtered projects for ' + name)

                this.selectedCountry = ''
                this.selectedISO3 = ''

                if (this.selectedTarget == name || name == '') {
                    this.selectedTarget = ''
                    this.selectedCode = 'total'
                    this.selectedName = 'Total'
                    this.calcProjectsKeys(this.projectsData)

                } else {
                    this.selectedTarget = name
                    this.selectedCode = 'pj'
                    this.selectedName = 'Total'
                    this.calcProjectsKeys(this.filteredProjects)
                }

                this.toggleButtons()
                this.selectedPjs = this.filteredProjects.length

                if (this.map) {
                    this.map.removeLayer('countries-data')
                    this.colorCountries()
                }
            },
            buildMap() {
                var vm = this

                if (this.map)
                    this.map.remove()

                this.map = new mapboxgl.Map({
                    container: this.$refs.map,
                    //style: 'mapbox://styles/interfase/ckylc2phv6pxw14qilb9kg0wp?fresh=true',
                    style: 'mapbox://styles/interfase/ckz5m7u5y001415qo3xjqm2q9?fresh=true',
                    zoom: 2.7,
                    minZoom: 2,
                    maxZoom: 7,
                    center: [6.5, 58]
                })

                this.map.scrollZoom.disable();
                const nav = new mapboxgl.NavigationControl();
                this.map.addControl(nav, 'bottom-left');

                this.map.on('load', () => {
                    this.map.addSource('countries', {
                        type: 'vector',
                        url: 'mapbox://mapbox.country-boundaries-v1'
                    });

                    this.map.addLayer({
                        'id': 'state-fills',
                        'type': 'fill',
                        'source': 'countries',
                        "source-layer": "country_boundaries",
                        'paint': {
                            'fill-color': vm.hoverMapColor,
                            'fill-opacity': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                1,
                                0
                            ]
                        }
                    }, 'country-labels');

                    this.map.addLayer({
                        'id': 'state-stroke',
                        'type': 'line',
                        'source': 'countries',
                        "source-layer": "country_boundaries",
                        'paint': {
                            'line-color': vm.darkMapColor,
                            'line-width': 0.3
                        }
                    }, 'state-fills');

                    this.map.addLayer({
                        'id': 'state-halo',
                        'type': 'line',
                        'source': 'countries',
                        "source-layer": "country_boundaries",
                        'paint': {
                            'line-color': 'rgb(180, 180, 180)',
                            'line-opacity': 0.5,
                            'line-width': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                1,
                                0
                            ]
                        }
                    }, 'country-labels');

                    this.colorCountries()

                })

                this.map.on('click', function (e) {
                    var point = vm.map.queryRenderedFeatures(e.point, {
                        layers: ['state-fills']
                    })[0]

                    //if it finds a country under hit point
                    if (point) {
                        //search it among possible countries

                        console.log(vm.countryKeys)

                        var found = false
                        vm.countryKeys.forEach(d => {
                            if (d[0] == point.properties.iso_3166_1_alpha_3) {
                                //and set the selection in the variables
                                vm.selectedName = point.properties.name_en
                                vm.selectedCountry = point.properties.name_en
                                vm.selectedISO3 = point.properties.iso_3166_1_alpha_3
                                vm.selectedPjs = d[1]

                                found = true
                            }
                            if (vm.selectedCode == 'total')
                                vm.selectedCode = 'country-total'
                            if (vm.selectedCode == 'pj')
                                vm.selectedCode = 'country-pj'
                        })

                        if (!found) {
                            vm.selectedCountry = ''
                            vm.selectedISO3 = ''
                            //and set the total values back to parent scope
                            //based on Working Group selected (if any) or to entire QTEdu
                            if (vm.selectedTarget == '') {
                                vm.selectedCode = 'total'
                                vm.selectedName = 'Total'
                                vm.selectedPjs = vm.projectsData.length
                            } else {
                                vm.selectedCode = 'pj'
                                vm.selectedName = 'Total'
                                vm.selectedPjs = vm.filteredProjects.length
                            }
                        }

                        //else unset the country selection
                    } else {
                        vm.selectedCountry = ''
                        vm.selectedISO3 = ''
                        //and set the total values back to parent scope
                        //based on Working Group selected (if any) or to entire QTEdu
                        if (vm.selectedTarget == '') {
                            vm.selectedCode = 'total'
                            vm.selectedName = 'Total'
                            vm.selectedPjs = vm.projectsData.length
                        } else {
                            vm.selectedCode = 'pj'
                            vm.selectedName = 'Total'
                            vm.selectedPjs = vm.filteredProjects.length
                        }
                    }

                    /* console.log('------------ ------------ ------------')
                    console.log('selectedCode', vm.selectedCode)
                    console.log('selectedName', vm.selectedName)
                    console.log('selectedCountry', vm.selectedCountry)
                    console.log('selectedISO3', vm.selectedISO3)
                    console.log('selectedPjs', vm.selectedPjs) */
                })

                let hoveredStateId = null
                this.map.on('mousemove', function (e) {
                    var point = vm.map.queryRenderedFeatures(e.point, {
                        layers: ['state-fills']
                    })

                    if (hoveredStateId !== null) {
                        vm.map.setFeatureState({
                            source: 'countries',
                            sourceLayer: 'country_boundaries',
                            id: hoveredStateId
                        }, {
                            hover: false
                        });
                    }

                    if (point.length) {
                        let found = false
                        vm.countryKeys.forEach(d => {
                            if (d[0] == point[0].properties.iso_3166_1_alpha_3)
                                found = true
                        })

                        if (found) {
                            vm.displayHover = true
                            hoveredStateId = point[0].id
                            vm.map.setFeatureState({
                                source: 'countries',
                                sourceLayer: 'country_boundaries',
                                id: hoveredStateId
                            }, {
                                hover: true
                            })
                            vm.countryKeys.forEach(d => {
                                if (d[0] == point[0].properties.iso_3166_1_alpha_3) {
                                    vm.tooltip_top = e.point.y - 30
                                    vm.tooltip_left = e.point.x + 30
                                    vm.hoverMembers = d[1]
                                }
                            })

                        } else
                            vm.displayHover = false
                    } else
                        vm.displayHover = false

                });

                // When the mouse leaves the state-fill layer, update the feature state of the
                // previously hovered feature.
                this.map.on('mouseleave', 'state-fills', () => {
                    if (hoveredStateId !== null) {
                        this.map.setFeatureState({
                            source: 'countries',
                            sourceLayer: 'country_boundaries',
                            id: hoveredStateId
                        }, {
                            hover: false
                        });
                    }
                    hoveredStateId = null;
                });
            }
        },
        async mounted() {
            this.loading = true

            await this.fetchprojectsData()
            await this.buildMap()

            //this.selectTarget('Higher education')

            this.selectedCode = 'total'
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styles/variables.scss';

    .teaser.pink {
        color: $pink;
        margin: 0;
    }

    .teaser {
        margin: 0;
    }

    .moving-tooltip {
        display: none;
    }

    .underlined {
        position: relative;

        &:after {
            background: url('../assets/underline_title_lightblue.svg');
            width: 80%;
            height: 1rem;
            bottom: -1.5rem;
            left: 20px;
            content: "";
            position: absolute;
            z-index: 0;
        }

        &:before {
            background: url('../assets/icon_rombo_shade.svg');
            width: 1rem;
            height: 1rem;
            bottom: -1.5rem;
            left: 10px;
            content: "";
            position: absolute;
            z-index: 1;
        }
    }

    .map {
        position: relative;
        height: 80vh;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    ::v-deep .mapboxgl-ctrl-bottom-right {
        z-index: 5;
    }

    .btn.border.active {
        background-image: $gradient;

        span {
            -webkit-text-fill-color: #fff
        }
    }

    .pannello {
        position: relative;
        overflow: hidden;
    }

    .map-info {
        margin-top: 1rem;
        font-size: 2em;
        text-align: center;
        pointer-events: none;
    }

    .wg-map-info {
        padding: 0 1rem;
        width: 100%;

        .box {
            display: flex;
            flex-direction: row;
            justify-content: center;

            >p {
                margin: 0;
                font-size: 1rem;
                font-style: italic;
            }
        }
    }

    .box div {
        width: 50%;

        .num {
            position: relative;
            margin: 0;
            font-size: 3rem;
            line-height: 1em;
            font-family: $primaryFont;
            color: $primaryColor;
            padding: 0 0 .5rem 0;
        }

        .didascalia {
            margin: 0;
            display: flex;
            justify-content: center;

            span {
                margin-left: 5px;
                display: block;
            }
        }

        &.tot-members {

            .didascalia .subject,
            .didascalia .country {
                font-size: 16px;
                margin-left: .15em;
            }

            .num {
                background: $gradient;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }

        .total-label {
            font-size: 1.5rem;
            line-height: 2.5rem;
            margin-top: 9px;
            display: block;

            &+.didascalia {
                margin-top: 0;
            }
        }
    }

    // .button-grid {
    //     background: #fff;
    //     height: 100%;
    //     bottom: 0;
    //     left: 0;
    //     z-index: 4;

    //     &.hidden {
    //         background: transparent;
    //         position: relative;
    //         pointer-events: none;

    //         .read-more {
    //             pointer-events: all;
    //             cursor: pointer;

    //             p {
    //                 margin-top: 1.2rem;
    //                 margin-bottom: .5em;
    //                 font-size: 1.25rem;
    //                 background: $gradient;
    //                 background-clip: text;
    //                 -webkit-text-fill-color: transparent;
    //             }
    //         }

    //         .btn {
    //             display: none;
    //         }

    //         .selected-wg {
    //             pointer-events: all;
    //             color: #fff;
    //             cursor: pointer;

    //             &.close:before {
    //                 background-image: url(../assets/icon_close_grey-w.svg);
    //                 right: .1em;
    //             }
    //         }
    //     }

    // }

   




    /* --------------- --------------- 1024PX --------------- --------------- */

    @media (min-width: 1024px) {
  
  .button-grid.fixed,
        .button-grid.hidden {
            display: flex;
            flex-direction: column;
            position: relative;
            top: 19%;
            left: 0;
            width: 50%;

            &:before {
                content: 'Level';
                position: absolute;
                color: #fff;
                font-size: 2em;
                top: -1.5em;
                left: 0;
                font-family: $primaryFont;
            }

            &:after {
                content: 'Filter by:';
                position: absolute;
                color: #aeb2ce;
                font-size: 16px;
                top: -68px;
                left: 0px;
                font-family: $primaryFont;
            }

            .btn {
                width: 100%;

                span {
                    display: block;
                    font-size: 16px;
                }
            }
        }

        .moving-tooltip {
            display: flex;
            background-color: rgba($primaryColor, .7);
            height: 50px;
            width: 50px;
            border-radius: 50%;
            flex-direction: column;
            justify-content: center;

            h2 {
                color: #fff;
                width: 100%;
                height: 100%;
                margin-bottom: .75em;
                text-align: center;
            }
        }

        .map {
            height: 100vh
        }

        .map-info {
            width: 35%;
            height: 100vh;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;

            margin-top: 0;
            padding-top: 10vh;
            background: linear-gradient(to left, $primaryColor, transparent);
        }

        .wg-map-info {
            width: 100%;
            height: 100%;
            padding: 1rem 0;
            position: relative;
        }

        .box {
            width: 70%;
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: auto;
            top: 64px;
            right: 2vw;
            text-align: left;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 2px dashed rgba(255, 255, 255, 0.5);

            div {
                width: 100%;

                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: end;

                .num {
                    margin: auto;
                    font-size: 3rem;
                    color: #fff;
                    margin-right: 0;
                }

                p {
                    text-align: right;
                    width: fit-content;
                    margin: 1rem 0 0 0;
                    color: #fff;
                }
            }

            &>p {
                color: rgba(#fff, .8);
            }

            .didascalia {
                margin: 0;
                display: flex;
                justify-content: center;
                flex-direction: column;

                span {
                    margin-left: 0px;
                    display: block;
                }
            }

            div.tot-members .didascalia {
                flex-direction: column;
            }

            div.tot-members .didascalia,
            div.countries .didascalia {
                font-size: 1rem;
            }

            div.tot-members .didascalia .country,
            div.countries .didascalia .country {
                font-size: 22px;
                font-weight: 400;
            }

            div.countries .didascalia .subject,
            div.tot-members .didascalia .subject {
                font-size: 18px;
            }

        }

        // .map-country-baloon {
        //     width: 50%;
        //     height: 90vh;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-between;
        //     left: 2vw;
        //     padding: 0 1rem;
        // }

        .button-grid {
            .btn.contains {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    width: calc(100% + 6px);
                    height: calc(100% + 6px);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    padding: 6px;
                    border-radius: 50%;
                    background: $gradient border-box;
                    -webkit-mask:
                        linear-gradient(#fff 0 0) content-box,
                        linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
            }
        }

        .button-grid.hidden,
        .button-grid.fixed {
            height: 100%;
            pointer-events: none;
            position: relative;
            bottom: unset;
            width: 100%;
            background-color: transparent;
            padding: 0;
            display: block;
            top: 4vh;

            .read-more,
            .selected-wg {
                display: none;
            }

            .btn {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                min-width: unset;
                height: 50px;
                position: relative;
                pointer-events: all;
                padding: 0;
                background: rgba(#fff, .2);
                border: 2px dashed #fff;
                width: 60%;
                margin: .5em 0;
                border-radius: 0;

                &:hover,
                &.active:hover {
                    background: rgba(255, 255, 255, .5);

                    .pj-icon {
                        width: 34px;
                        height: 34px;
                        margin: 0 1rem;
                        background-color: rgba(255, 255, 255, .7);
                        border-radius: 50%;
                        background-size: 52%;

                        &.general-public {
                            background-image: url(../assets/targets/icon_transversal_small_blue.svg);
                        }

                        &.higher-education {
                            background-image: url(../assets/targets/icon_higher_small_blue.svg);
                        }

                        &.industry {
                            background-image: url(../assets/targets/icon_lifelong_small_blue.svg);
                        }

                        &.schools {
                            background-image: url(../assets/targets/icon_primary_small_blue.svg);
                        }

                    }
                }

                &.active {
                    border: 2px solid transparent;
                    background: $gradient padding-box, $gradient border-box;

                    .pj-icon {
                        width: 20px;
                        height: 20px;
                        margin: 0 1.35rem 0 1.5rem;

                        &.general-public {
                            background-image: url(../assets/targets/icon_transversal_small_white.svg);
                        }

                        &.higher-education {
                            background-image: url(../assets/targets/icon_higher_small_white.svg);
                        }

                        &.industry {
                            background-image: url(../assets/targets/icon_lifelong_small_white.svg);
                        }

                        &.schools {
                            background-image: url(../assets/targets/icon_primary_small_white.svg);
                        }

                    }
                }

                span {
                    color: #fff;
                    -webkit-text-fill-color: unset;
                    background-image: unset;
                    background-clip: unset;
                    height: fit-content;
                    position: relative;
                    transform: translateY(-50%);
                    top: 50%;
                    margin: 0;
                    text-align: left;
                }

                .pj-icon {
                    width: 34px;
                    height: 34px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: relative;
                    transform: translateY(-50%);
                    top: 50%;
                    margin: 0 1rem;

                    &.general-public {
                        background-image: url(../assets/targets/icon_transversal_small_circle_shade.svg);
                    }

                    &.higher-education {
                        background-image: url(../assets/targets/icon_higher_small_circle_shade.svg);
                    }

                    &.industry {
                        background-image: url(../assets/targets/icon_lifelong_small_circle_shade.svg);
                    }

                    &.schools {
                        background-image: url(../assets/targets/icon_primary_small_circle_shade.svg);
                    }
                }
            }
        }

        
    }

    /* --------------- --------------- 1200PX --------------- --------------- */

    @media (min-width: 1200px) {

        
 
        // .map-country-baloon {
        //     margin-top: 15vh;
        // }

        .button-grid.fixed,
        .button-grid.hidden {
            .btn {
                height: 60px;
                min-height: 60px;
            }
        }

        .map-info {
            width: 40%;
        }

        .wg-map-info {
            margin-top: 5vh;
            height: 85vh;
        }

        .box {
            div .num {
                font-size: 4rem;
            }

            div.tot-members .didascalia,
            div.countries .didascalia {
                font-size: 1.2rem;
            }

            div.tot-members .didascalia .country,
            div.countries .didascalia .country {
                font-size: 1.5rem;
            }
        }
    }

    /* --------------- --------------- 1400PX --------------- --------------- */

    @media (min-width: 1400px) {



        // .map-country-baloon {
        //     margin-top: 15vh;
        //     height: 85vh;

        //     h3 {
        //         font-size: 2rem;
        //     }
        // }

        .map-info {
            width: 40%;
        }

        .wg-map-info {
            margin-top: 5vh;
            height: 85vh;
        }

        .box {

            div .num {
                font-size: 5rem;
            }

            div.tot-members .didascalia,
            div.countries .didascalia {
                font-size: 20px;
                line-height: 1.5em;
            }

            div.tot-members .didascalia .country,
            div.countries .didascalia .country {
                font-size: 1.75rem;
            }

            div.tot-members .didascalia:nth-child(2),
            div.countries .didascalia:nth-child(2) {
                margin: .5rem 0 0 0;
            }
        }

                
    }

    @media (min-width: 1600px) {
        .map-info {
            width: 33.33%;
        }
    }

    @media (min-width: 1700px) {

        // .map-country-baloon {
        //     h1 {
        //         font-size: 4em;

        //         &.pink {
        //             font-size: 3rem;
        //         }
        //     }
        // }
    }

    /* BALOON STYLE */
    .baloon {
        position: absolute;
        right: .25rem;
        top: 2rem;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: row;
        align-items: center;

        .num {
            font-size: 3em;
            margin-top: -27px;
            background-image: $gradient;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-family: $primaryFont;
            margin-right: 1rem;
        }
    }

    /* --------------- BALOON STYLE --------------- */

    .baloon {
        visibility: hidden;
    }

    @media (min-width: 345px) {
        .baloon {
            right: 1rem;
        }
    }

    @media (min-width: 400px) {
        .baloon {
            top: 0;
        }
    }

    @media (min-width: 768px) {
        .baloon {
            visibility: visible;
            text-align: left;
        }

        .container .members-teaser {
            margin-right: 210px;
        }
    }

    @media (min-width: 1024px) {

        .pannello>.container>.teaser,
        .pannello>.container>h2 {
            padding: 0 40px;
        }

        .baloon {
            right: 80px;
            top: 1rem;

            .num {
                font-size: 4.5rem;
            }

            .members {
                font-size: 1.25rem;
            }
        }

        .page-layout div.button-grid.fixed,
        .page-layout div.button-grid {
            border-bottom: 0px solid #e6e6e6;
            position: absolute;
            left: 41px;
            top: 380px;
            width: auto;
            margin: 0;
            bottom: auto;
            padding: 0;

            .btn {
                width: 100%;
                padding: 0px 16px 0px 0px;
            }
        }
    }

    @media (min-width: 1400px) {

        .page-layout div.button-grid.fixed,
        .page-layout div.button-grid {
            left: 51px;
            top: 378px;

            .btn {
                width: 100%;
                padding: 5px 17px 5px 0px;
            }
        }

        .page-layout div.button-grid.fixed {
            width: auto;
            padding: 0;

            .btn {
                width: 100%;
                height: 50px;
            }
        }
    }

    @media (min-width: 1700px) {

        .page-layout div.button-grid.fixed,
        .page-layout div.button-grid {
            top: 408px;
            left: 59px;
        }
    }
</style>